#menu__toggle {
    opacity: 0;
    visibility: collapse;
    left: 0;

  }
  
  #menu__toggle_checked ~ .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle_checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }
  #menu__toggle_checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
    
  }
  #menu__toggle_checked ~ .menu__box {
    visibility: visible;
    left: 0;
    
  }
  
  .menu__btn {
    
      
    display: flex;
    align-items: center;
    position: fixed;
    top: 20px;
    left: 20px;
    
    width: 50px;
    height: 50px;
  
    cursor: pointer;
    z-index: 99997;
    
  }
  
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    z-index: 99998;
    width: 100%;
    height: 2px;
    
    background-color: #444444;
  
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -16px;
  }
  .menu__btn > span::after {
    content: '';
    top: 16px;
  }
  
  .menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: -100%;
  
    width: 300px;
    height: 100%;
  
    margin: 0;
    padding: 80px 0;
    z-index: 99996;
    list-style: none;
  
    background-color: #ECEFF1;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
  
    transition-duration: .25s;
  }
  input{
    z-index: 99999;
    position: fixed;
    width: 50px;
    height: 50px;
    left: 12px;
    top: 15px;
    
    cursor: pointer;
    background: rgba(40,40,40,0);
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
  }
  input:checked {
   
    width: 50px;
    height: 50px;
    left: 20px;
    top: 18px;
    padding: 2%;
   
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
  
    color: #333;
  
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
  
    text-decoration: none;
  
    transition-duration: .25s;
  }
  .menu__item:hover {
    background-color: #CFD8DC;
  }

  .a{
      
      font-size: 18px;
      
      padding: 15px;
      text-transform: uppercase;
  }
    a{
        color: black!important;
        text-decoration: none!important;
    }
  .current{
      font-weight: bold;
     
  }
.disappear{
    opacity: 0;
}
 .appear{
     opacity: 1;
 }


.hidden {
        visibility: hidden;
    }

    .flex{
        text-align: center
    }
    .flex_row{
      display: flex;
     
    }
    .absolute_languages{
        position: relative;
        z-index: 9999999;
        left: 80px;
        top: 50px;
    }

    .btn-toggle1,  .btn-toggle2{
      background-color: transparent;
      border: none;
      margin-right: 15px;
      cursor: pointer;
    }
    @media only screen and (max-width: 1250px) {
      #menu__toggle {
        z-index: 9999999!important;
        opacity: 0;
        visibility: collapse;
        left: 12px;
        cursor: pointer;
        
      }
      .menu__btn > span,
      .menu__btn > span::before,
      .menu__btn > span::after {
       
        display: block;
        position: absolute;
      
        width: 100%;
        height: 2px;
      
        background-color: #f7f7f7;
      
        transition-duration: .25s;
        
      }


    }