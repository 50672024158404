@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
.flex{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.container{
    margin-top: 50px;
    width: 80%;
    max-width: 1600px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.container_small{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2vh;
}

.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    border-radius: 50%;
    animation: load8 3s infinite linear;
    transition: opacity 0.3s;
  }
  
  .loader--hide {
    opacity: 0;
  }
  
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .full_width{
    width: 100%;
  }
  .full_h{
  
    
    margin-bottom: 0!important;
    background: url("./img/bles.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  
    vertical-align: middle;
    height: 100vh;
    width: 100%!important;
}

.container_all{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1250px) {

  .container_all{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .full_h{
  
    height: auto;
    margin-bottom: 0!important;
    background: url("./img/bles.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  
    vertical-align: middle;
    height: auto;
    padding-top: 20%;
    padding-bottom: 20%;
    width: 100%;
    margin-bottom: 0;
}

}