.btn-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    object-fit: cover;
    cursor: pointer;
    background: transparent;
    border: none;
}

img{
    width: 60px;
    height: 60px;
    object-fit: cover;
}