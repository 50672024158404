.full_width{
    width: 100%;
    height: auto;
}
.bg{
    
        
        background: linear-gradient(#ececec, #fafddd);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
}

.bt{
    margin-bottom: 0;
    padding-bottom: 50px;
}

.mt{
    
    padding-top: 50px; 
}

.small{
    text-align: center;
    font-size: 1.3em;
    font-weight: lighter;
}

.link{
    font-size: 2em;
    color: rgb(99, 127, 204)!important;
    display: block;
    text-align: center;
}