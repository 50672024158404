@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
.flex{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.container{
    margin-top: 50px;
    width: 80%;
    max-width: 1600px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.container_small{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2vh;
}

.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    border-radius: 50%;
    animation: load8 3s infinite linear;
    transition: opacity 0.3s;
  }
  
  .loader--hide {
    opacity: 0;
  }
  
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .full_width{
    width: 100%;
  }
  .full_h{
  
    
    margin-bottom: 0!important;
    background: url(/static/media/bles.ae94b90b.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  
    vertical-align: middle;
    height: 100vh;
    width: 100%!important;
}

.container_all{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1250px) {

  .container_all{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .full_h{
  
    height: auto;
    margin-bottom: 0!important;
    background: url(/static/media/bles.ae94b90b.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  
    vertical-align: middle;
    height: auto;
    padding-top: 20%;
    padding-bottom: 20%;
    width: 100%;
    margin-bottom: 0;
}

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
    margin: 0;
    padding: 0;
}


video{
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    height: 100%;
}

.bg-white{
    height: 50vh;
    background: white;
}
p{
    
    font-size: 50px;
   
    
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.bg-name{
    
    position: absolute;
    top: 50vh;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background-color: white;
    color: #000;
    height: 14vh;
    font-weight: bold;
    text-align: center;
    line-height: var(--center-line);
    mix-blend-mode: screen;
    padding:0;
    letter-spacing: 15px;
    font-family: 'Staatliches', cursive;
}
h1{
    font-size: 15vh;
}
.bg-acc{
    background-color: transparent;
    height: 50vh;
    width: 100%;
}

/* scroll down */



 .scroll1{
    left: 50%;
    transform: translateY(0%) rotate(45deg);
    width: 2em; height: 2em; background-color: transparent; z-index: 80; 
            bottom: 5vh; position: absolute; border-width: 0 0.25em 0.25em 0; border-style: solid; border-color: rgb(0, 0, 0); animation: scrolldown 1.2s ease-in-out infinite 0.15s;

  }
  .scroll2{
    left: 50%;
    transform: translateY(0%) rotate(45deg);
    width: 2em; height: 2em; background-color: transparent; z-index: 80; 
    bottom: 8vh; position: absolute; border-width: 0 0.25em 0.25em 0; border-style: solid; border-color: rgb(0, 0, 0); animation: scrolldown 1.2s ease-in-out infinite;
  }
 
  @keyframes scrolldown {
    0%{
        transform: translateY(20%) rotate(45deg);
        opacity: 0.7;
    }
    50%{
        transform: translateY(0%) rotate(45deg);
        opacity: 0.2;
    }
    100%{
        transform: translateY(20%) rotate(45deg);
        opacity: 0.7;
    }
}

.acc_img{
    width: 75px;
    height: 75px;
    object-fit: cover;
    padding: 2%;
    background-color: white;
}

h2{
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.left ul li{
    list-style: none;
    font-size: 25px;
    padding: 5%;
    color: rgb(63, 100, 86);
    font-weight: bold;
}
.left{
    margin-right: 5%;
}
.mail{
    position: relative;
    text-align: center;
    font-size: 1.6em;
}
.white{
    color: white;
}
.full_height_acc{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white; 
}
.languages{
    margin-bottom: 30px;
    width: 80%;
    background-color: white;
  
}
.flex2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: white;
}
.flex{
    background-image: url(/static/media/bles.ae94b90b.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    
}

@media only screen and (max-width: 1250px) {

    .bg-image{
        height: 100vh;
    }
    .bg-white{
        height: 0vh;
        background: white;
    }
    p{
        
        font-size: 50px;
       
        
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .bg-name{
       
        
            
        
        position: absolute;
      
        background-color: white;
        color: #000;
       
        font-weight: bold;
 
        line-height: var(--center-line);
        mix-blend-mode: screen;
        padding:0;
        letter-spacing: 15px;
        font-family: 'Staatliches', cursive;
        height: auto;
        top: 40%;
        bottom: 40%;
        font-size: 2rem;
    }
    h1{
        font-size: 2rem;
    }
    .bg-acc{
        background-color: transparent;
        height: 50vh;
        width: 100%;
    }
    .scroll1{
        left: 46%;
        transform: translateY(0%) rotate(45deg);
        width: 2em; height: 2em; background-color: transparent; z-index: 80; 
                top: 60vh; position: absolute; border-width: 0 0.25em 0.25em 0; border-style: solid; border-color: rgb(0, 0, 0); animation: scrolldown 1.2s ease-in-out infinite 0.15s;
    
      }
      .scroll2{
        left: 46%;
        transform: translateY(0%) rotate(45deg);
        width: 2em; height: 2em; background-color: transparent; z-index: 80; 
        top: 57vh; position: absolute; border-width: 0 0.25em 0.25em 0; border-style: solid; border-color: rgb(0, 0, 0); animation: scrolldown 1.2s ease-in-out infinite;
      }
     
      @keyframes scrolldown {
        0%{
            transform: translateY(20%) rotate(45deg);
            opacity: 0.7;
        }
        50%{
            transform: translateY(0%) rotate(45deg);
            opacity: 0.2;
        }
        100%{
            transform: translateY(20%) rotate(45deg);
            opacity: 0.7;
        }
    }
    .flex2{
        
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: white;
    }
    .full_height_acc{
        height: auto;
        margin-top: 15vh;
    }
}

*{
    margin: 0;
    padding: 0;
}

.header_page{
    background-color: white;
    height: 92vh;
    margin: 0 auto;
    width: 65vw;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 1%
}

.in_header_page, .p_forma{
    position: relative;
    
    object-fit: cover;
    font-size: 1em;
    font-weight: lighter;
}

.p_forma, h3{
    text-align: center;
   
    font-size: 1.2em;
    font-weight: lighter;
}
h3{
    font-size: 1.6em;
    text-transform: uppercase;
    font-family: 'Staatliches', cursive;
}
.forma{
    font-size: 1.6em;
    text-transform: uppercase;
    font-family: 'Staatliches', cursive;
    margin-top: 0;
}

.enhance{
    font-size: 1.2em;
}
.cadre{
    border: 1px solid lightgray;
    padding: 1%;
    margin-right: 2%;
    margin-left: 2%;
}


.in_header_page::before{
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-top: 2px solid black;
    border-left: 2px solid black;
  
    left: 0vh;
    z-index: 9999;
}
.in_header_page::after{
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    right: 0vh;
    margin-top: -3.7%;
    z-index: 9999;
}

.margins{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1250px) {

    .header_page{
        background-color: white;
        height: auto;
        margin: 0 auto;
        width: 80vw;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       padding: 1%
    }
    
    .in_header_page, .p_forma{
        position: relative;
        
       
        font-size: 1.2em;
        font-weight: lighter;
    }
    
    .p_forma, h3{
        text-align: center;
       
        font-size: 1em;
        font-weight: lighter;
    }
    h3{
        font-size: 1em;
        text-transform: uppercase;
        font-family: 'Staatliches', cursive;
    }
    .forma{
        font-size: 1.2em;
        text-transform: uppercase;
        font-family: 'Staatliches', cursive;
        
    }
    
    .enhance{
        font-size: 1em;
    }
    .cadre{
        border: 1px solid lightgray;
        padding: 1%;
        margin-right: 2%;
        margin-left: 2%;
    }
    
    
    .in_header_page::before{
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        border-top: 2px solid black;
        border-left: 2px solid black;
      
        left: 0vh;
        z-index: 9999;
    }
    .in_header_page::after{
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        right: 0vh;
        margin-top: -10%;
        z-index: 9999;
    }
    
    .margins{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }




}
*{
    margin: 0;
    padding: 0;
}


h4{
    font-size: 1.6em;
}
.bigger{
    font-size: 1.2em;
}

.control-arrow{
    background-color: black!important;
    opacity: 0.8;
}

.first_exp, .ex_pro, .internship{
    padding: 5%;
   
}
.col{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.header_page_exp{
    background-color: white;
    height: auto;
    margin: 0 auto;
    width: 80vw;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
  
}
.title_exp{
    font-size: 1.3em!important;
}
@media only screen and (max-width: 1250px) {
    h4{
        font-size: 1.5em;
    }
    
    .bigger{
        font-size: 1.4em;
    }
    
    .control-arrow{
        background-color: black!important;
        opacity: 0.8;
    }
    
    .first_exp, .ex_pro, .internship{
        padding: .5%;
       
    }
    .col{
        height: 100%;
        padding-left: 10%;
        padding-right: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .slider-wrapper{
        width: 82vw!important;
    }
    .header_page_exp{
        background-color: white;
        height: auto;
        margin: 0 auto;
        width: 80vw;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       padding-left: 1%;
       padding-right: 1%;
    }

}
.full_width{
    width: 100%;
    height: auto;
}
.bg{
    
        
        background: linear-gradient(#ececec, #fafddd);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
}

.bt{
    margin-bottom: 0;
    padding-bottom: 50px;
}

.mt{
    
    padding-top: 50px; 
}

.small{
    text-align: center;
    font-size: 1.3em;
    font-weight: lighter;
}

.link{
    font-size: 2em;
    color: rgb(99, 127, 204)!important;
    display: block;
    text-align: center;
}
.russian {
    display: inline-block;
    height: 15px;
    width: 60px;
    border: 1px solid black;
    background-color: rgb(201, 201, 201);
    margin-left: 30px;
}

.de, .en, .es, .ru{
    display: inline-block;
    width: 100px;
    margin: 0;
    padding: 0;
}
.rest, .rest1{
    height: 15px;
    width: 20px;
    border: 1px solid black;
    display: inline-block;
}
.rest2{
    height: 15px;
    width: 40px;
    border: 1px solid black;
    display: inline-block;
}
.rest3{
    height: 15px;
    width: 60px;
    border: 1px solid black;
    display: inline-block;

}

.spanish {
    display: inline-block;
    height: 15px;
    width: 80px;
    border: 1px solid black;
    background-color: rgb(201, 201, 201);
    margin-left: 30px;
    
    
}

.german, .english {
    display: inline-block;
    height: 15px;
    width: 100px;
    border: 1px solid black;
    background-color: rgb(201, 201, 201);
    margin-left: 30px;
   
}
img{
    object-fit: cover;
}
.img_lan_w{
    width: 80px;
}

button{
    background: transparent;
    border: none;
    
}

.bigger-img{
    cursor: pointer;
    width: 80px;
}

img.fancybox__image{
   
   transform: scale(13);
}

.fancybox__content{
    width: auto;
    height: auto;
}

.more_size{
    font-size: 1.6em;
    font-family: 'Staatliches', cursive;
}

.display_center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    height: 100vh;
    width: 100%;
}
.bg-white-languages{
    background-color: white;
    padding: 5%;
    border-radius: 3px;
    box-shadow: 2px 2px 2px rgb(141, 140, 140);
}
@media only screen and (max-width: 1250px) {
    img.fancybox__image{
   
        transform: scale(6);
     }
}
.mail_contact{
    font-size: 2em;
}

#menu__toggle {
    opacity: 0;
    visibility: collapse;
    left: 0;

  }
  
  #menu__toggle_checked ~ .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle_checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }
  #menu__toggle_checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
    
  }
  #menu__toggle_checked ~ .menu__box {
    visibility: visible;
    left: 0;
    
  }
  
  .menu__btn {
    
      
    display: flex;
    align-items: center;
    position: fixed;
    top: 20px;
    left: 20px;
    
    width: 50px;
    height: 50px;
  
    cursor: pointer;
    z-index: 99997;
    
  }
  
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    z-index: 99998;
    width: 100%;
    height: 2px;
    
    background-color: #444444;
  
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -16px;
  }
  .menu__btn > span::after {
    content: '';
    top: 16px;
  }
  
  .menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: -100%;
  
    width: 300px;
    height: 100%;
  
    margin: 0;
    padding: 80px 0;
    z-index: 99996;
    list-style: none;
  
    background-color: #ECEFF1;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
  
    transition-duration: .25s;
  }
  input{
    z-index: 99999;
    position: fixed;
    width: 50px;
    height: 50px;
    left: 12px;
    top: 15px;
    
    cursor: pointer;
    background: rgba(40,40,40,0);
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
  }
  input:checked {
   
    width: 50px;
    height: 50px;
    left: 20px;
    top: 18px;
    padding: 2%;
   
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
  
    color: #333;
  
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
  
    text-decoration: none;
  
    transition-duration: .25s;
  }
  .menu__item:hover {
    background-color: #CFD8DC;
  }

  .a{
      
      font-size: 18px;
      
      padding: 15px;
      text-transform: uppercase;
  }
    a{
        color: black!important;
        text-decoration: none!important;
    }
  .current{
      font-weight: bold;
     
  }
.disappear{
    opacity: 0;
}
 .appear{
     opacity: 1;
 }


.hidden {
        visibility: hidden;
    }

    .flex{
        text-align: center
    }
    .flex_row{
      display: flex;
     
    }
    .absolute_languages{
        position: relative;
        z-index: 9999999;
        left: 80px;
        top: 50px;
    }

    .btn-toggle1,  .btn-toggle2{
      background-color: transparent;
      border: none;
      margin-right: 15px;
      cursor: pointer;
    }
    @media only screen and (max-width: 1250px) {
      #menu__toggle {
        z-index: 9999999!important;
        opacity: 0;
        visibility: collapse;
        left: 12px;
        cursor: pointer;
        
      }
      .menu__btn > span,
      .menu__btn > span::before,
      .menu__btn > span::after {
       
        display: block;
        position: absolute;
      
        width: 100%;
        height: 2px;
      
        background-color: #f7f7f7;
      
        transition-duration: .25s;
        
      }


    }
.btn-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    object-fit: cover;
    cursor: pointer;
    background: transparent;
    border: none;
}

img{
    width: 60px;
    height: 60px;
    object-fit: cover;
}
.btn-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    object-fit: cover;
    cursor: pointer;
    background: transparent;
    border: none;
}

img{
    width: 60px;
    height: 60px;
    object-fit: cover;
}
