*{
    margin: 0;
    padding: 0;
}

.header_page{
    background-color: white;
    height: 92vh;
    margin: 0 auto;
    width: 65vw;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 1%
}

.in_header_page, .p_forma{
    position: relative;
    
    object-fit: cover;
    font-size: 1em;
    font-weight: lighter;
}

.p_forma, h3{
    text-align: center;
   
    font-size: 1.2em;
    font-weight: lighter;
}
h3{
    font-size: 1.6em;
    text-transform: uppercase;
    font-family: 'Staatliches', cursive;
}
.forma{
    font-size: 1.6em;
    text-transform: uppercase;
    font-family: 'Staatliches', cursive;
    margin-top: 0;
}

.enhance{
    font-size: 1.2em;
}
.cadre{
    border: 1px solid lightgray;
    padding: 1%;
    margin-right: 2%;
    margin-left: 2%;
}


.in_header_page::before{
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-top: 2px solid black;
    border-left: 2px solid black;
  
    left: 0vh;
    z-index: 9999;
}
.in_header_page::after{
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    right: 0vh;
    margin-top: -3.7%;
    z-index: 9999;
}

.margins{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1250px) {

    .header_page{
        background-color: white;
        height: auto;
        margin: 0 auto;
        width: 80vw;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       padding: 1%
    }
    
    .in_header_page, .p_forma{
        position: relative;
        
       
        font-size: 1.2em;
        font-weight: lighter;
    }
    
    .p_forma, h3{
        text-align: center;
       
        font-size: 1em;
        font-weight: lighter;
    }
    h3{
        font-size: 1em;
        text-transform: uppercase;
        font-family: 'Staatliches', cursive;
    }
    .forma{
        font-size: 1.2em;
        text-transform: uppercase;
        font-family: 'Staatliches', cursive;
        
    }
    
    .enhance{
        font-size: 1em;
    }
    .cadre{
        border: 1px solid lightgray;
        padding: 1%;
        margin-right: 2%;
        margin-left: 2%;
    }
    
    
    .in_header_page::before{
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        border-top: 2px solid black;
        border-left: 2px solid black;
      
        left: 0vh;
        z-index: 9999;
    }
    .in_header_page::after{
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        right: 0vh;
        margin-top: -10%;
        z-index: 9999;
    }
    
    .margins{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }




}