.russian {
    display: inline-block;
    height: 15px;
    width: 60px;
    border: 1px solid black;
    background-color: rgb(201, 201, 201);
    margin-left: 30px;
}

.de, .en, .es, .ru{
    display: inline-block;
    width: 100px;
    margin: 0;
    padding: 0;
}
.rest, .rest1{
    height: 15px;
    width: 20px;
    border: 1px solid black;
    display: inline-block;
}
.rest2{
    height: 15px;
    width: 40px;
    border: 1px solid black;
    display: inline-block;
}
.rest3{
    height: 15px;
    width: 60px;
    border: 1px solid black;
    display: inline-block;

}

.spanish {
    display: inline-block;
    height: 15px;
    width: 80px;
    border: 1px solid black;
    background-color: rgb(201, 201, 201);
    margin-left: 30px;
    
    
}

.german, .english {
    display: inline-block;
    height: 15px;
    width: 100px;
    border: 1px solid black;
    background-color: rgb(201, 201, 201);
    margin-left: 30px;
   
}
img{
    object-fit: cover;
}
.img_lan_w{
    width: 80px;
}

button{
    background: transparent;
    border: none;
    
}

.bigger-img{
    cursor: pointer;
    width: 80px;
}

img.fancybox__image{
   
   transform: scale(13);
}

.fancybox__content{
    width: auto;
    height: auto;
}

.more_size{
    font-size: 1.6em;
    font-family: 'Staatliches', cursive;
}

.display_center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    height: 100vh;
    width: 100%;
}
.bg-white-languages{
    background-color: white;
    padding: 5%;
    border-radius: 3px;
    box-shadow: 2px 2px 2px rgb(141, 140, 140);
}
@media only screen and (max-width: 1250px) {
    img.fancybox__image{
   
        transform: scale(6);
     }
}